<template>
    <div class="layout-config h-100 p-10">
        <Row type="flex" :gutter="10">
            <Col span="4">
                <Input v-model="param.query" placeholder="请输入研学路线名称" clearable />
            </Col>
            <Col span="4">
                <Button type="primary" @click="getData()">查询</Button>
                <Button type="success" class="m-l-5" @click="showModal" v-if="isAuth">新增</Button>
            </Col>
        </Row>
        <Table border :columns="cols" :data="table.data" ref="table" class="m-t-10" />
        <Page :page="param.current" @on-change="getData" :page-size="10" :total="table.total" show-total class="text-r m-t-10" />
        <Modal v-model="modal.show" :title="`${modal.data.researchLineId ? '编辑' : '新增'}研学路线`" width="1200" :loading="modal.loading" @on-ok="saveData">
            <Form ref="form" :model="modal.data" :rules="modal.rules" :label-width="120" label-colon>
                <Row :gutter="20">
                    <Col span="11">
                        <FormItem label="研学路线名称" prop="lineName">
                            <Input v-model="modal.data.lineName" placeholder="请输入研学路线名称" clearable />
                        </FormItem>
                        <FormItem label="所属学科">
                            <SelectDict v-model="modal.data.subjects" :dict="dict.subject" multiple placeholder="请选择研学点所属学科" />
                        </FormItem>
                        <FormItem label="所属机构">
                            <Input v-model="modal.data.lineOrg.base" placeholder="研学路线所属机构（自动获取）" readonly />
                        </FormItem>
                        <!-- <FormItem label="所属活动">
                            <Input v-model="modal.data.lineActivity" placeholder="研学路线所属活动（自动获取）" readonly />
                        </FormItem> -->
                        <FormItem label="所包含研学点">
                            <SelectPoint v-model="modal.data.linePoints" placeholder="请选择所包含的研学点" isArray />
                        </FormItem>
                        <FormItem label="研学路线描述" prop="lineInfo">
                            <Input v-model="modal.data.lineInfo" type="textarea" placeholder="请输入研学路线描述" clearable />
                        </FormItem>
                        <FormItem label="采集人" prop="linePeople">
                            <Input v-model="modal.data.linePeople" placeholder="请输入研学路线采集人" clearable />
                        </FormItem>
                        <!-- <FormItem label="所属政区" prop="lineOrg.division">
                            <Input v-model="modal.data.lineOrg.division" placeholder="请输入所属政区" clearable />
                        </FormItem> -->
                        <FormItem label="所属学段" prop="applicablePhases">
                            <SelectDict v-model="modal.data.applicablePhases" :dict="dict.applicablePhases" multiple checkbox placeholder="所属学段" clearable />
                        </FormItem>
                        <FormItem label="适合季节" prop="lineSeason">
                            <SelectDict v-model="modal.data.lineSeason" :dict="dict.season" placeholder="请选择季节" clearable />
                        </FormItem>
                        <FormItem label="是否公开" prop="published">
                            <RadioGroup v-model="modal.data.published">
                                <Radio :label="1">是</Radio>
                                <Radio :label="0">否</Radio>
                            </RadioGroup>
                        </FormItem>
                        <FormItem label="高度" v-if="modal.data.lineCenter">
                            <Input v-model="modal.data.lineCenter.ele" placeholder="请输入高度" />
                        </FormItem>
                        <FormItem label="所属研学课程">
                            <GeoFile v-model="modal.data.lineCourse" text="研学课程" upload-txt="上传课程" :course="true" :param="{ folderId: 2164, userId: $store.state.user.userId }" />
                        </FormItem>
                        <FormItem label="研学路线图片">
                            <GeoFile v-model="modal.data.lineImg" text="选择图片" upload-txt="上传图片" :param="{ folderId: 2166, userId: $store.state.user.userId }" />
                        </FormItem>
                    </Col>
                    <Col span="13">
                        <FormItem label="获取位置">
                            <Map :data="modal.data" :height="400" :line="true" setAdd="lineAdd" setPoint="lineCenter" setOrg="lineOrg" v-if="modal.show" />
                        </FormItem>
                        <FormItem label="研学路线位置" prop="lineAdd">
                            <Input v-model="modal.data.lineAdd" placeholder="请输入研学路线位置(点击地图自动获取)" clearable />
                        </FormItem>
                        <FormItem label="经度" v-if="modal.data.lineCenter">
                            <Input v-model="modal.data.lineCenter.lon" placeholder="请输入经度(点击地图自动获取)" readonly />
                        </FormItem>
                        <FormItem label="纬度" v-if="modal.data.lineCenter">
                            <Input v-model="modal.data.lineCenter.lat" placeholder="请输入纬度(点击地图自动获取)" readonly />
                        </FormItem>
                        <FormItem label="研学路线视频">
                            <GeoFile v-model="modal.data.lineVideo" text="选择视频" upload-txt="上传视频" :param="{ folderId: 2167, userId: $store.state.user.userId }" />
                        </FormItem>
                        <!-- <FormItem label="研学路线全景图">
                            <GeoFile v-model="modal.data.line360" text="选择图片" upload-txt="上传图片" />
                        </FormItem> -->
                    
                    </Col>
                </Row>
            </Form>
        </Modal>
        <Modal v-model="view.show" title="查看研学路线" width="1000" footer-hide>
            <Row :gutter="10">
                <Col span="12">
                    <Vgroup :label-width="120">
                        <Vcell label="研学路线名称：" :value="view.data.lineName" />
                        <Vcell label="所属学科：">
                            <Tag v-for="(item, index) in view.data.subjects" :key="index">{{ item }}</Tag>
                        </Vcell>
                        <template v-if="view.data.lineOrg">
                            <Vcell label="所属机构：" :value="view.data.lineOrg.base" />
                        </template>
                        <Vcell label="研学路线描述：" :value="view.data.lineInfo" />
                        <Vcell label="采集人：" :value="view.data.linePeople" />
                        <!-- <Vcell label="所属政区：" :value="view.data.linePeople" /> -->
                        <Vcell label="所包含的研学点：">
                            <Tag v-for="(item, index) in view.data.linePoints" :key="index">{{ item.pointName }}</Tag>
                        </Vcell>
                        <Vcell label="所属学段：">
                            <Tag v-for="(item, index) in view.data.applicablePhases" :key="index">{{ item }}</Tag>
                        </Vcell>
                        <Vcell label="适合季节：" :value="view.data.lineSeason" />
                        <Vcell label="研学路线位置：" :value="view.data.lineAdd" />
                        <template v-if="view.data.lineCenter">
                            <Vcell label="经度：" :value="view.data.lineCenter.lon" />
                            <Vcell label="纬度：" :value="view.data.lineCenter.lat" />
                            <Vcell label="高度：" :value="view.data.lineCenter.ele" />
                        </template>
                        <Vcell label="所属研学课程：">
                            <Tag v-for="(item, index) in view.data.lineCourse" :key="index">
                                <span class="c-h" @click="openCourse(item)">{{ item }}</span>
                            </Tag>
                        </Vcell>
                        <Vcell label="研学路线图片：">
                            <PhotoView :value="view.data.lineImg" />
                        </Vcell>
                        <Vcell label="研学路线视频：">
                            <VideoView :value="view.data.lineVideo" />
                        </Vcell>
                        <!-- <Vcell label="研学路线全景图：" :value="view.data.line360" /> -->
                    </Vgroup>
                </Col>
                <Col span="12">
                    <Map :data="view.data" :height="500" :setAddress="false" v-if="view.show" />
                </Col>
            </Row>
        </Modal>
        <CourseView :modal="course" />
    </div>
</template>
  
<script>
  import { util } from '@/libs'
  import { dict } from '@/config'
  import { getLine, getPointsByLineId, getLineById, addLine, updateLine, deleteLine } from '@/libs/api/trace'
  import { mapState } from 'vuex'
  import CourseView from '@/views/dataV/index/courseView.vue'
  export default {
    components: { CourseView },
    data() {
        const _this = this;
        return {
            isAuth: null,
            dict,
            param: {
                current: 1,
                size: 10,
                order: 'lineName',
            },
            uploadData: {
                folderId: 2173,
                userId: this.$store.state.user.userId
            },
            table: {},
            modal: {
                show: false,
                loading: false,
                data: {
                    lineCenter: { ele: 500 },
                    lineOrg: {},
                },
                rules: {
                    lineName: { required: true, message: "请输入研学路线名称" }
                }
            },
            view: {
                show: false,
                data: {
                    lineCenter: {},
                    lineOrg: {},
                }
            },
            course: {
                show: false,
                data: {}
            },
            cols: [
                { title: "序号", type: "index", key: "index", width: 70, align: "center" },
                { title: "研学路线名称", key: "lineName" },
                { title: "研学路线位置", key: "lineAdd", render(h, w) { return h("span", w.row.lineAdd && w.row.lineAdd.join(",")); } },
                { title: "研学路线描述", key: "lineInfo", render(h, w) {
                    return h('Tooltip', {
                        class: 'w-100',
                        props: {
                            content: w.row.lineInfo,
                            trigger: 'hover',
                            size: 'small',
                            transfer: true,
                            placement: 'bottom-start',
                            maxWidth: 400
                        }
                    }, [h('div', { class: 'ivu-table-cell-ellipsis',  }, w.row.lineInfo)])}
                },
                { title: "操作", width: 200,
                    render(h, w) {
                        const btns = []
                        btns.push(h("Button", {
                            props: { type: "info", size: "small" },
                            on: {
                                async click() {
                                    let linePoints = []
                                    const { data } = await getPointsByLineId({ researchLineId: w.row.researchLineId })
                                    if(data && data.length) {
                                        linePoints = data
                                    }
                                    Object.assign(_this.view, {
                                        show: true,
                                        data: {
                                            ...w.row,
                                            linePoints,
                                            lineAdd: w.row.lineAdd && w.row.lineAdd.join(",")
                                        }
                                    })
                                }
                            }
                        }, "查看"))
                        if(_this.isAuth) {
                            btns.push(
                                h("Button", {
                                props: { type: "success", size: "small" },
                                on: {
                                    async click() {
                                        let linePoints = []
                                        const { data } = await getPointsByLineId({ researchLineId: w.row.researchLineId })
                                        if(data && data.length) {
                                            linePoints = data.map(item => item.researchPointId)
                                        }
                                        getLineById({ id: w.row.researchLineId }).then(res => {
                                            Object.assign(_this.modal, {
                                                show: true,
                                                data: {
                                                    ...res.data,
                                                    linePoints,
                                                    lineAdd: res.data.lineAdd && res.data.lineAdd.join(","),
                                                    published: res.data.published == true ? 1 : (res.data.published == false ? 0 : null)
                                                }
                                            })
                                        })
                                    }
                                }
                            }, "编辑"),
                                h("Button", {
                                    props: { type: "error", size: "small" },
                                    on: {
                                        click() {
                                            _this.$Modal.confirm({
                                                title: "删除",
                                                content: `是否删除当前${w.row.lineName}?`,
                                                onOk: () => {
                                                    deleteLine({ id: w.row.researchLineId }).then((res) => {
                                                        _this.successResult(res, "删除成功！")
                                                    });
                                                }
                                            });
                                        }
                                    }
                                }, "删除")
                            )
                        }
                        return btns
                    }
                },
            ],
        };
    },
    computed: {
        ...mapState({
            userId: state => state.user.userId,
            roleCode: state => state.user.roleCode
        })
    },
    methods: {

        // 打开课程
        openCourse(item) {
            if(item) {
                let id = item.split('/')[item.split('/').length - 1]
                Object.assign(this.course, { show: true, data: { fileName: '研学课程', id } })
            }
        },

        // 打开弹框
        showModal() {
            const organizationName = this.$store.state.user.organization.organizationName
            this.$refs["form"].resetFields();
            Object.assign(this.modal, { show: true, data: { lineCenter: { ele: 500 }, lineOrg: { base: organizationName }, published: 1 } });
        },

        // 保存数据
        saveData() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    const { data } = this.modal;
                    let params = { ...data, lineAdd: data.lineAdd && data.lineAdd.split(",") };
                    
                    util.setNullParams(params)
                    if (data.researchLineId) {
                        // 修改API
                        updateLine(params).then(res => {
                            this.successResult(res, '编辑成功!')
                        });
                    }
                    else {
                        // 新增API
                        addLine(params).then(res => {
                            this.successResult(res, '添加成功!')
                        });
                    }
                }
            });
        },
        // 新增修改成功回调
        successResult(res, descMsg) {
            if (res.code == "HA0200") {
                this.$Message.success(descMsg || res.msg);
                this.getData();
            }
            else {
                this.$Message.error(res.msg);
            }
        },
        // 获取数据列表
        getData(page) {
            Object.assign(this.param, { current: page ? page : 1, order: this.param.query ? 'lineName' : '' })
            // { userId: this.$store.state.user.userId, ...this.param }
            getLine(this.param).then(res => {
                res.code == "HA0200" ? this.table = res : this.$Message.error(res.msg)
            });
        }
    },
    mounted() {
        this.isAuth = this.roleCode.includes('admin') || this.roleCode.includes('mechanismAdmin') || this.roleCode.includes('teach') || this.roleCode.includes('yxpt-t')
        this.getData();
    }
}
</script>
  
<style lang="less" scoped>
  @media (min-width: 1024px) {
      .layout-config {
          
      }
  
  }
</style>
  